import { PageLayout } from "@freshbuffer/core/PageLayout";
import { Button, Typography } from "@mui/material";
import { useIntl } from "apps/freshbuffer/src/core/Intl";
import { NavLink } from "apps/freshbuffer/src/core/Routing/NavLink";

import styles from "./HowFreshbufferWorks.module.css";
import { useMediaIsDesktop } from "@freshbuffer/utilities/Media/useMediaIsDesktop";
import React from "react";
import { ROUTES } from "@freshbuffer/core/Routing";

export const HowFreshBufferWorks = () => {
  const { T, locale } = useIntl();
  const isDesktop = useMediaIsDesktop();

  return (
    <PageLayout.SectionContainer className={styles.root}>
      <div className={styles.twoReactangleContainer}>
        <div className={styles.rectangle1}></div>
        <div className={styles.rectangle2}></div>
        <div className={styles.textContent}>
         { isDesktop ?  (<h1>{T("TEAM_WORK_BANNER_TITLE")}</h1>) : (<h2>{T("TEAM_WORK_BANNER_TITLE")}</h2>)} 
          {isDesktop && (
            <React.Fragment>
              <p>{T("TEAM_WORK_BANNER_SUB_TITLE")}</p>
              <NavLink.Native  href={ROUTES.ContactUs.url({}, locale)} className={styles.button}>
              {T("CONNECT_WITH_OUR_TEAM")}
              </NavLink.Native>
            </React.Fragment>
          )}
        </div>
      </div>

      <section className={styles.container}>
        <div className={styles.card}>
          <Typography variant="h4">{T("WHY_FRESHBUFFER_TITLE_1")}</Typography>
          <Typography variant="body2" className={styles.cardText}>
            {T("WHY_FRESHBUFFER_DESC_1")}
          </Typography>
        </div>
        <div className={styles.card}>
          <Typography variant="h4">{T("WHY_FRESHBUFFER_TITLE_2")}</Typography>
          <Typography variant="body2" className={styles.cardText}>
            {T("WHY_FRESHBUFFER_DESC_2")}
          </Typography>
          <Button variant="link" href="#">
            Read more
          </Button>
        </div>
        <div className={styles.card}>
          <Typography variant="h4">{T("WHY_FRESHBUFFER_TITLE_3")}</Typography>
          <Typography variant="body2" className={styles.cardText}>
            {T("WHY_FRESHBUFFER_DESC_3")}
          </Typography>
        </div>
        <div className={styles.card}>
          <Typography variant="h4">{T("WHY_FRESHBUFFER_TITLE_4")}</Typography>
          <Typography variant="body2" className={styles.cardText}>
            {T("WHY_FRESHBUFFER_DESC_4")}
          </Typography>
        </div>
      </section>

      <div className={styles.letUsTalkButton}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={NavLink.Anchor}
          href="#"
        >
          {T("TALK_TO_US")}
        </Button>
      </div>
    </PageLayout.SectionContainer>
  );
};
