import { PageLayout } from "@freshbuffer/core/PageLayout";
import { ChangingText } from "./ChangingText";
import styles from "./WelcomeBanner.module.css";

export const WelcomeBanner = () => {
  return (
    <PageLayout.MainContainer className={styles.root}>
      <div className={styles.container}>
        <div className={styles.bannerImage} />
        <div className={styles.animations}>
          <ChangingText />
         
        </div>
      </div>
    </PageLayout.MainContainer>
  );
};
