import { NextPage } from "next";

import { useIntl } from "apps/freshbuffer/src/core/Intl";
import { PageLayout } from "apps/freshbuffer/src/core/PageLayout";
import { ROUTES } from "apps/freshbuffer/src/core/Routing";
import { Careers } from "./components/Careers";
import { HowFreshBufferWorks } from "./components/HowFreshbufferWorks";
import { WelcomeBanner } from "./components/WelcomeBanner";
import { WhatWeDo } from "./components/WhatWeDo";

const Home: NextPage = () => {
  const { T } = useIntl();

  return (
    <PageLayout
      pageTitle={T("HOME_PAGE_TITLE")}
      metaDescription={T("HOME_PAGE_METADATA")}
      route={ROUTES.Home}
    >
      <PageLayout.MainRaw>
        <WelcomeBanner />

        <WhatWeDo />
        <HowFreshBufferWorks />
        {/* <OurPartners /> */}
        <Careers />
      </PageLayout.MainRaw>
    </PageLayout>
  );
};

export default Home;
