import { useMediaIsDesktop } from "@freshbuffer/utilities/Media/useMediaIsDesktop";
import { Typography } from "@mui/material";
import { useIntl } from "apps/freshbuffer/src/core/Intl";
import { PageLayout } from "apps/freshbuffer/src/core/PageLayout";
import styles from "./WhatWeDo.module.css";
import classNames from "classnames";
import { CommonStyles } from "@freshbuffer/styles/CommonStyles";

export const WhatWeDo = () => {
  const { T } = useIntl();
  const isDesktop = useMediaIsDesktop();
  return (
    <PageLayout.MainContainer
      data-testid="section/what-we-do"
      className={styles.root}
    >
      <section className={ !isDesktop ? CommonStyles.padding_inline_3 : ''}>
        <Typography variant="subtitle1" className={CommonStyles.padding_bottom_2}>{T("HOME_WHAT_WE_DO")}</Typography>
        <Typography variant={isDesktop ? "h2" : "h3"}>
          {T("HOME_WHAT_WE_DO_DESC")}
        </Typography>
      </section>

      <div className={classNames(styles.divider, styles.section)} />
      <section className={ !isDesktop ? CommonStyles.padding_inline_3 : ''}>
        <Typography variant="subtitle1" className={CommonStyles.padding_bottom_2}>{T("HOME_OUR_OFFERINGS")}</Typography>
        <Typography variant={isDesktop ? "h2" : "h3"}>{T("HOME_OUR_OFFERINGS_DESC")}</Typography>
      </section>
    </PageLayout.MainContainer>
  );
};
