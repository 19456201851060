import React from "react";
import { useIntl } from "apps/freshbuffer/src/core/Intl";
import { Button, Typography } from "@mui/material";
import { PageLayout } from "apps/freshbuffer/src/core/PageLayout";

import styles from "./Careers.module.css";
import { CommonStyles } from "@freshbuffer/styles/CommonStyles";
import classNames from "classnames";

export const Careers = () => {
  const { T } = useIntl();

  return (
    <PageLayout.SectionContainer
      id="Careers"
      data-testid="section/Careers"
      className={styles.root}
    >
       <Typography variant="subtitle1" className={ classNames(CommonStyles.padding_bottom_2, styles.header)}>{T("GENERAL_CAREERS")}</Typography>
      <div className={styles.container}>
        <div className={styles.card}>
          <Typography variant="h5">{T("HOME_CAREERS_SUBTITLE")}</Typography>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            className={styles.button}
            href=""
          >
            {T("GENERAL_GET_IN_TOUCH")}
          </Button>
        </div>
      </div>
    </PageLayout.SectionContainer>
  );
};
