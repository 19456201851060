import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./ChangingText.module.css";
import { useIntl } from "@freshbuffer/core/Intl";

export const ChangingText = () => {

  const { T } = useIntl();
  
  const texts = [
    T("HOME_BANNER_TEXT_1"),
    T("HOME_BANNER_TEXT_2"),
    T("HOME_BANNER_TEXT_3"),
    T("HOME_BANNER_TEXT_4"),
    T("HOME_BANNER_TEXT_5"),
    T("HOME_BANNER_TEXT_6"),
    T("HOME_BANNER_TEXT_7"),
    T("HOME_BANNER_TEXT_8"),
    T("HOME_BANNER_TEXT_9"),
  ];

  const [currentText, setCurrentText] = useState(texts[0]);
  const [index, setIndex] = useState(0);
  const [fadeProp, setFadeProp] = useState({ opacity: 0.5 });

  useEffect(() => {
    const interval = setInterval(() => {
   setFadeProp({ opacity: 0 });
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFadeProp({ opacity: 1 });
      }, 3000);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const highlightText = (text: string | undefined) => {
    const parts = text?.split(/(Fresh)/gi);
    return parts?.map((part, index) =>
      part.toLowerCase() === "fresh" ? (
        <span key={index} className={styles.root}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  useEffect(() => {
    setCurrentText(texts[index]);
  }, [index, texts]);

  return (
    <React.Fragment>
      <Typography
        variant="h3"
        className={styles.text}
        style={{...fadeProp }}
      >
        {highlightText(currentText)}
      </Typography>
    </React.Fragment>
  );
};
